@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, input, button, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

sup {
  font-size: 70%;
  vertical-align: top;
  position: relative;
  top: -0.1em; }

sub {
  font-size: 70%;
  vertical-align: bottom;
  position: relative; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

* {
  min-height: 0vw; }

*::-moz-selection {
  background: #005fbb;
  color: #fff; }

*::selection {
  background: #005fbb;
  color: #fff; }

html {
  font-size: 62.5%; }
  @media screen and (max-width: 23.375em) {
    html {
      font-size: 51.5%; } }

body {
  font-family: "Noto Sans JP", "游ゴシック", "メイリオ", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic Pro" , "Lucida Grande", "Osaka","Verdana", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #444444;
  text-align: center;
  line-height: 1.75;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: .16em;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%; }
  @media screen and (max-width: 47.9375em) {
    body {
      min-width: 0; } }

a {
  text-decoration: none;
  color: #444444; }

img {
  max-width: 100%;
  -webkit-backface-visibility: hidden; }

button {
  border: none;
  padding: 0;
  background: none; }
