@charset "UTF-8";

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　PATH
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// usage: #{$imgPath}
$imgPath: "/img/";

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　COLOR
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$fontColor: #444444;
$txtColor:#222222;
$txtBlue:#1564c5;
$mainColor: #005fbb;
$ttlColor: #032a80;
$subColor:#00b9c4;
$btnColor:#f7a51e;
$bgColor:#eef0f6;
$keyColor: #000;


// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　FONT
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

$fontsize: 1.6rem;
$fontsizeSp: 1.4rem;


$fontName: 'name', sans-serif;
$fontBarlow: 'Barlow', sans-serif;
$fontHraginoW3:"ヒラギノ角ゴ ProN W3", "游ゴシック", "メイリオ", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic Pro" , "Lucida Grande", "Osaka","Verdana", "ＭＳ Ｐゴシック", sans-serif;;
$fontHraginoW6:"ヒラギノ角ゴ ProN W6", "游ゴシック", "メイリオ", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic Pro" , "Lucida Grande", "Osaka","Verdana", "ＭＳ Ｐゴシック", sans-serif;;



// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　Media Query
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// $columnwidth__01: 1240px;
// $columnwidth__02: 1020px;

$wraper: 100%;

$breakpointpc: "screen and (max-width: 90em)";
$breakpointpcsmall: "screen and (max-width: 80em)";
$breakpointtablarge: "screen and (max-width: 73.75em)";
$breakpointtab: "screen and (max-width: 64em)";
$breakpointtabsmall: "screen and (max-width: 60em)";
$hoveronly: "(hover: hover) and (pointer: fine)";
$breakpoint: "screen and (max-width: 47.9375em)";
$breakpointsplarge: "screen and (max-width: 26.5625em)";
$breakpointsp: "screen and (max-width: 23.375em)";
$ie11: "all and (-ms-high-contrast:none)";
$iphone5: "screen and (max-width: 20em)";

