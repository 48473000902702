* { 
  min-height: 0vw; 
}

*::-moz-selection {
  background: $mainColor;
  color: #fff;
}

*::selection {
  background: $mainColor;
  color: #fff;
}
html {
  font-size: 62.5%;
  @media #{$breakpointsp}{
    font-size: 51.5%;
  }
}
body {
  // min-width: 1200px;
  font-family: "Noto Sans JP", "游ゴシック", "メイリオ", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic Pro" , "Lucida Grande", "Osaka","Verdana", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: $fontColor;
  text-align: center;
  line-height: (56/32);
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: .16em;
  overflow-x: hidden;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: subpixel-antialiased;
  // -moz-osx-font-smoothing: auto;
  -webkit-text-size-adjust:100%;
  @media #{$breakpoint}{
    min-width: 0;
    // font-size: $fontsizeSp;
  }
}
a {
  text-decoration: none;
  color: $fontColor;
}
img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
}
button {
  border: none;
  padding: 0;
  background: none;
}
